import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class DialogService {
	dialogRef?: MatDialogRef<unknown>;

	constructor(private dialog: MatDialog) {}

	openDialog(
		component: ComponentType<unknown>,
		options?: {
			data?: unknown,
			disableClose?: boolean,
			maxWidth?: string
		}
	): MatDialogRef<unknown> {
		this.dialogRef = this.dialog.open(component, {
			data: options?.data,
			disableClose: options?.disableClose ?? false,
			maxWidth: options?.maxWidth ?? '100%'
		});

		return this.dialogRef;
	}
}