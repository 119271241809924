<mat-card class="cp-cta-card" role="group" [attr.aria-label]="cardAriaLabel" [ngClass]="{'cp-cta-half-width-card': !shiftImages, 'cp-cta-full-width-card': shiftImages, 'cp-cta-card-no-content': !content, 'cp-cta-card': content}">
	<ng-container *ngIf="!shiftImages">
		<div class="cta-card-img-container">
			<img mat-card-image
				class="cp-cta-img"
				*ngFor="let image of images;"
				[src]="image"
				alt=""
				data-testid="cp-cta-img">
		</div>
	</ng-container>
	<mat-card-header class="cp-cta-card-header">
		<mat-card-title>
			<h2 class="cp-cta-card-header-text" data-testid="cp-cta-card-header">
				{{header}}
			</h2>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content *ngIf="content" class="cp-cta-card-content" [ngClass]="{'cp-justify-text': shiftImages}" data-testid="cp-cta-card-content">
		<p>{{content}}</p>
	</mat-card-content>
	<ng-container *ngIf="shiftImages">
		<div class="cp-cta-shifted-image-container">
			<div class="cp-center-content-column" *ngFor="let image of images, let i = index;">
				<img mat-card-image
					class="cp-cta-shifted-image"
					[src]="image"
					alt=""
					data-testid="cp-cta-img">
				<ng-container *ngIf="imageCaptions.length">
					<p class="cp-cta-shifted-image-text">{{imageCaptions[i]}}</p>
				</ng-container>
			</div>
		</div>
	</ng-container>
	<mat-card-actions class="cp-cta-button-container">
		<a *ngIf="internalButtonLink && !externalButtonLink"
			mat-button
			[routerLink]="internalButtonLink"
			[color]="buttonColorPrimary ? 'primary' : 'accent'"
			[attr.data-id]="ctaTelemetryLabel"
			data-testid="cp-cta-internal-button-link">
			{{buttonLabel}}
		</a>
		<a *ngIf="externalButtonLink && !internalButtonLink"
			mat-button
			target="_blank"
			[href]="externalButtonLink"
			color="accent"
			[attr.data-id]="ctaTelemetryLabel"
			data-testid="cp-cta-external-button-link">
			<mat-icon fontSet="material-symbols-outlined" iconPositionEnd aria-hidden="false" [attr.aria-label]="iconAriaLabel">open_in_new</mat-icon>
			{{buttonLabel}}
		</a>
		<button *ngIf="!externalButtonLink && !internalButtonLink"
			mat-button
			color="accent"
			[attr.data-id]="ctaTelemetryLabel"
			data-testid="cp-cta-modal-button-link"
			(click)="buttonClick.emit()"
			(keypress)="keyPressHandler($event, buttonClick.emit())">
			{{buttonLabel}}
		</button>
	</mat-card-actions>	
</mat-card>