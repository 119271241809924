import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Component({
	selector: 'cp-invalid-error-page',
	templateUrl: './invalid-error-page.component.html',
	styleUrls: ['./invalid-error-page.component.scss']
})
export class InvalidErrorPageComponent extends UnsubscribeOnDestroy {
	myAccountUrl: string | undefined;

	errorHeader: string | undefined;

	errorText: string | undefined;

	constructor(
		private brandService: BrandService,
		private settingsService: SettingsService,
		private router: Router,
	) {
		super();

		const navigationState = this.router.getCurrentNavigation()?.extras.state as { data: number };

		this.settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			const brand = this.brandService.getBrand();
			this.myAccountUrl = settings.brandConfigs[brand.name].logoutUrl;

			if (navigationState?.data === 404) {
				this.errorHeader = 'Sales Agreement Not Found';
				this.errorText = `We're sorry we couldn't find a valid sales agreement. You can try again or return to <a href="${this.myAccountUrl}">MyAccount</a> for more information.`;
			} else {
				this.errorHeader = 'Uh oh! Something went wrong.';
				this.errorText = `We're sorry we encountered an error on our end. You can try again later or return to <a href="${this.myAccountUrl}">MyAccount</a> for more resources.`;
			}
		});
	}
}