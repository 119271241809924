import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { asyncScheduler, filter, observeOn, scan, take } from 'rxjs';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { ScrollService } from 'src/app/modules/core/services/scroll.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { SpinnerService } from 'src/app/modules/core/services/spinner.service';

import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';
import { ConstructionProgressFacade } from 'src/app/modules/ngrx-store/construction-progress/construction-progress.facade';
import { ServiceRequestsFacade } from 'src/app/modules/ngrx-store/service-requests/service-requests.facade';
import { YourHomeFacade } from 'src/app/modules/ngrx-store/your-home/your-home.facade';

import { Breakpoints } from 'src/app/modules/shared/types/breakpoints';

import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';
import { InternalUrlPipe } from 'src/app/modules/shared/pipes/internal-url.pipe';
import { NAVIGATION_ROUTES } from 'src/app/modules/shared/constants/route-paths';

interface ScrollPositionRestore {
	event: Event;
	positions: { [K: number]: number };
	trigger: 'imperative' | 'popstate' | 'hashchange';
	idToRestore: number;
}

@Component({
	selector: 'cp-customer-portal',
	templateUrl: './customer-portal.component.html',
	styleUrls: ['./customer-portal.component.scss']
})
export class CustomerPortalComponent extends UnsubscribeOnDestroy implements OnInit  {
	@Input() salesAgreementId?: number;

	@ViewChild('sidenav') sidenav?: MatSidenav;
	@ViewChild('cpNavContainer') cpNavContainer?: ElementRef;
	@ViewChild('cpMainContent') cpMainContent?: ElementRef;

	homeDetails$ = this.yourHomeFacade.homeDetails$;
	homeDetailsPending$ = this.yourHomeFacade.homeDetailsPending$;
	userInfo$ = this.appFacade.userInfo$;

	myAccountUrl: string | undefined;

	spinnerActive$ = this.spinnerService.spinnerActive$;

	constructor(
		public scrollService: ScrollService,
		public yourHomeFacade: YourHomeFacade,
		private breakpointObserver: BreakpointObserver,
		private router: Router,
		private appFacade: AppFacade,
		private brandService: BrandService,
		private settingsService: SettingsService,
		private constructionProgressFacade: ConstructionProgressFacade,
		private serviceRequestFacade: ServiceRequestsFacade,
		private internalUrlPipe: InternalUrlPipe,
		private spinnerService: SpinnerService
	) {
		super();
		// Scroll to top on emit - For when the user clicks the SCAR progress icon on the construction progress page
		this.scrollService.scrollToTop$.subscribe(() => {
			if (this.cpNavContainer) {
				this.cpNavContainer.nativeElement.scrollTop = 0;
			}

			if (this.cpMainContent) {
				this.cpMainContent.nativeElement.scrollTop = 0;
			}
		});

		this.settingsService.getSettings().subscribe(settings => {
			const brand = this.brandService.getBrand();

			this.myAccountUrl = settings.brandConfigs[brand.name].logoutUrl;
		});
	}

	ngOnInit(): void {
		this.breakpointObserver.observe(
			Breakpoints.desktop
		).pipe(this.takeUntilDestroyed())
			.subscribe((result: BreakpointState) => {
				if(result.breakpoints[Breakpoints.desktop]) {
				// Edge case handler for when you open sidenav in tablet and resize the browser to desktop, and the dark overlay exists
					this.sidenav?.close();
				}
			});

		// Uses the router events to determine if the scroll bar should be set at the top or a prior position
		this.router.events.pipe(
			filter(
				(event: Event) => event instanceof NavigationStart || event instanceof NavigationEnd,
			),
			// Scan acts as a reducer over time, storing all of the navigation events so that we can access
			// the scroll positions as necessary
			scan<Event, ScrollPositionRestore>((acc, event) => ({
				event,
				positions: {
					...acc.positions,
					...(event instanceof NavigationStart
						? { [event.id]: this.cpNavContainer?.nativeElement.scrollTop }
						: {}
					),
					...acc.positions,
					...(event instanceof NavigationStart
						? { [event.id]: this.cpMainContent?.nativeElement.scrollTop }
						: {}
					)
				},
				trigger:
					event instanceof NavigationStart && event.navigationTrigger
						? event.navigationTrigger
						: acc.trigger,
				idToRestore:
					(event instanceof NavigationStart && event.restoredState &&
						event.restoredState.navigationId + 1) || acc.idToRestore,
			}), {} as ScrollPositionRestore),
			filter(
				({ event, trigger }) => event instanceof NavigationEnd && !!trigger,
			),
			observeOn(asyncScheduler),
		).pipe(
			this.takeUntilDestroyed()
		).subscribe(({ trigger, positions, idToRestore }) => {
			if (this.cpMainContent && this.cpNavContainer) {
				if (trigger !== 'hashchange') {
					if (trigger === 'imperative') {
						this.cpNavContainer.nativeElement.scrollTop = 0;
						this.cpMainContent.nativeElement.scrollTop = 0;
					}
					
					if (trigger === 'popstate') {
						this.cpNavContainer.nativeElement.scrollTop = positions[idToRestore];
						this.cpMainContent.nativeElement.scrollTop = positions[idToRestore];
					}
				}
			}
		});

		// Check for existing home details
		this.yourHomeFacade.homeDetails$.pipe(
			take(1)
		).subscribe((homeDetails) => {
			// If browser storage does not have home details, or if the SAG ID doesn't match, fetch the details from API
			if (this.salesAgreementId && homeDetails?.salesAgreementId !== +this.salesAgreementId) {

				const sagChanged = homeDetails?.salesAgreementId && homeDetails?.salesAgreementId !== +this.salesAgreementId;
			
				// Reset data to ensure nothing lingers during loading of new home details
				this.constructionProgressFacade.resetConstructionUpdates();
				this.serviceRequestFacade.resetServiceRequests();
				this.yourHomeFacade.loadHomeDetails(this.salesAgreementId);
			
				if (sagChanged) {
					const transformedUrl = this.internalUrlPipe.transform(NAVIGATION_ROUTES.Dashboard);
					this.router.navigate([transformedUrl]);
				}
			} else {
				// If this is the same agreement, load new construction updates and service requests
				// Corresponding effects will prevent unnecessary API call based on current home phase
				this.constructionProgressFacade.loadConstructionUpdates();
				this.serviceRequestFacade.loadServiceRequests();
			}
		});
	}
}