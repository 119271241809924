import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ConstructionStageEnum } from 'src/app/modules/core/models/sales.model';

import { ScarStageService } from 'src/app/modules/construction-progress/service/scar-stage.service';
import { ScrollService } from 'src/app/modules/core/services/scroll.service';

import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Component({
	selector: 'cp-scar-progress-icon',
	templateUrl: './scar-progress-icon.component.html',
	styleUrls: ['./scar-progress-icon.component.scss']
})
export class ScarProgressIconComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
	// Whether this stage is currently in progress
	isActive: boolean = false;

	// Whether this stage has been completed
	isComplete: boolean = false;

	@Input() constructionStage: ConstructionStageEnum | undefined;

	@Input() closeOfEscrow: Date | undefined;

	// The name of the stage
	@Input() label: string = '';

	// Whether the label should be visible
	@Input() shouldShowLabel: boolean = true;

	// The numerical representation of the stage (for mobile)
	@Input() step: number = 0;

	// The icon of the stage
	@Input() svgIcon: string = '';
	@Input() matIcon: string = '';

	@Input() isClickable: boolean = false;

	scarSelected: boolean = false;

	constructor(
		private scarService: ScarStageService,
		private scrollService: ScrollService) {
		super();
	}

	ngOnInit(): void {
		this.updateSelectedScarStageId();
	}

	ngOnChanges(changes: SimpleChanges): void {
		// If the construction stage changes and it's clickable(user lands directly on the construction progress page), set the selected SCAR stage 
		if(changes['constructionStage'] && this.constructionStage !== undefined && this.isClickable) {
			this.scarService.setSelectedScarStageId(this.constructionStage);
		}

		if (this.closeOfEscrow) {
			// If CoE is populated then all stages are complete
			this.isActive = false;
			this.isComplete = true;
		} else if (this.constructionStage) {
			switch (this.step) {
				case 1:
					this.isActive = this.constructionStage === ConstructionStageEnum.Introduction
					this.isComplete = this.constructionStage > 1;
					break;
				case 2:
					this.isActive = this.constructionStage === ConstructionStageEnum.Excavation;
					this.isComplete = this.constructionStage > 2;
					break;
				case 3:
					this.isActive = this.constructionStage === ConstructionStageEnum.Frame;
					this.isComplete = this.constructionStage > 3;
					break;
				case 4:
					this.isActive = this.constructionStage === ConstructionStageEnum.Drywall;
					this.isComplete = this.constructionStage > 4;
					break;
				case 5:
					// isComplete doesn't need to be checked here because we are checking the CoE date earlier
					this.isActive = this.constructionStage === ConstructionStageEnum.Complete;
					break;
				default:
					this.isActive = false;
					this.isComplete = false;
					break;
			}
		}
	}

	updateSelectedScarStageId() {
		if (this.isClickable) {
			if (this.isActive) {
				this.scarService.setSelectedScarStageId(this.step);
			}

			this.scarService.selectedScarStageId$.pipe(
				this.takeUntilDestroyed()
			).subscribe((selectedId) => {
				this.scarSelected = selectedId === this.step;
			});
		}
	}

	scarIconSelected() {
		if (this.isClickable) {
			this.scarService.setSelectedScarStageId(this.step);
			this.scrollService.triggerScrollToTop(); // Scroll to top of the page
		}
	}
}
