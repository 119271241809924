import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { JobService } from 'src/app/modules/core/services/job.service';
import { SalesService } from 'src/app/modules/core/services/sales.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';

import * as YourHomeActions from 'src/app/modules/ngrx-store/your-home/your-home.actions';
import { ROUTES } from 'src/app/modules/shared/constants/route-paths';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Injectable()
export class YourHomeEffects extends UnsubscribeOnDestroy {

	myAccountUrl: string | undefined;

	constructor(
		private actions$: Actions,
		private jobService: JobService,
		private salesService: SalesService,
		private router: Router,
		private brandService: BrandService, 
		private settingsService: SettingsService) {
		super();
		this.settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			const brand = this.brandService.getBrand();
			this.myAccountUrl = settings.brandConfigs[brand.name].logoutUrl;
		});
	}

	// Retrieves the Home Details on the Your Home page.
	loadHomeDetails$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetails),
		switchMap(({ salesAgreementId }) => {
			if (salesAgreementId) {
				return this.salesService.getHomeDetails(salesAgreementId).pipe(
					map((homeDetails) => YourHomeActions.loadHomeDetailsSuccess({ homeDetails })),
					catchError((err) =>
						of(
							YourHomeActions.loadHomeDetailsFailure({
								error: err
							})
						))
				)			
			} else {
				return this.salesService.getHomeDetailsViaSso().pipe(
					map((homeDetails) => YourHomeActions.loadHomeDetailsSuccess({ homeDetails })),
					catchError((err) =>
						of(
							YourHomeActions.loadHomeDetailsFailure({
								error: err
							})
						)
					)
				)
			}
		})
	));

	// When loading Home Details fails, display an alert notifying the user of the error
	loadHomeDetailsFailure$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetailsFailure),
		tap((error) => {
			switch (error.error.status) {
				case 403:
				case 401:
					// Portal not enabled
					if (this.myAccountUrl) {
						window.location.href = this.myAccountUrl;
					}
					// TODO what if myAccountUrl is null somehow?
					break;
				case 404: 
					// Invalid SAG
					this.router.navigate([ROUTES.Error, ROUTES.SalesAgreementInvalid], { state: { data: error.error.status } })
					break;
				case 500:
				case 400:
				default:
					this.router.navigate([ROUTES.Error, ROUTES.ServerError], { state: { data: error.error.status } })
					break;
			}
		})
	),
	{ dispatch: false }
	);

	// Retrieves the Job Options (after Home Details have been successfully retrieved).
	loadJobOptions$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetailsSuccess),
		switchMap(({ homeDetails }) =>
			this.jobService.getJobOptions(homeDetails.jobId).pipe(
				map((jobOptions) => YourHomeActions.loadJobOptionsSuccess({ jobOptions })),
				catchError((err) =>
					of(
						YourHomeActions.loadJobOptionsFailure({
							error: err
						})
					))
			)
		)
	));
}